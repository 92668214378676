details,
summary {
  display: block;
}

summary {
  cursor: pointer;
}

.no-details {
  details {
    &:not([open]) > :not(summary) {
      display: none;
    }

    & > summary:before {
      content: '\25BC';
      display: inline-block;
      margin-right: 0.25em;
      transition: 0.1s;
      transform: rotate(-90deg);
    }

    &[open] > summary:before {
      transform: rotate(0deg);
    }
  }
}

.entry-toc {
  margin-bottom: 2.5em;

  ul {
    list-style: none;
  }

  nav {
    & > ul {
      margin: 0;
    }
  }
}
