.page-links {
  margin-top: 2em;
}

.page-link {
  display: inline-block;
  padding: 0.25em 0.625em;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  &.current {
    font-weight: 700;
  }

  &.dots {
    padding: 0;
  }
}
