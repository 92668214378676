.icon {
  display: inline-block;
  height: 1em;
  width: 1em;

  stroke: currentColor;
  fill: none;

  position: relative;
  top: 2px;
}

img.emoji {
  height: 1em;
  width: 1em;
  margin: 0 0.05em 0 0.1em;
  vertical-align: -0.1em;
}
