.entry-nav {
  .sep-before {
    &:nth-child(2) {
      &:before {
        width: 2em;
        margin: 1em 0;
      }
    }
  }

  div {
    a {
      display: block;
      font-style: italic;
    }

    span {
      display: block;
      font-style: normal;
      @include font-size(0.875);
    }
  }
}
