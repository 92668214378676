/*!
 * Theme Name: Minimo
 * Author: Munif Tanjim
 */

@import 'extras/extras';

/*****************
 # Normalize
*****************/
// @import 'normalize';

/*****************
 # Typography
*****************/
@import 'typography/typography';

/*****************
 # Elements
*****************/
@import 'elements/elements';

/*****************
 # Forms
*****************/
@import 'forms/forms';

/*****************
 # Navigation
*****************/
@import 'navigation/navigation';

/*****************
 # Layouts
*****************/
@import 'layouts/layouts';

/*****************
 # Accessibility
*****************/
@import 'extras/accessibility';
