.footer {
  .sep-before {
    &:before {
      border-bottom-color: $color__accent;
      border-bottom-color: var(--color-accent);
    }
  }

  a {
    font-style: italic;
  }

  .container > div,
  .container > section {
    width: 100%;
    margin-top: 1em;

    &:first-child {
      margin-top: 0;
    }
  }
}
