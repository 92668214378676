.widget {
  &-title {
    margin-bottom: 1em;
  }

  &.sep-after {
    &:after {
      margin-top: 1.5em;
      margin-bottom: 1.5em;
    }

    &:last-of-type {
      &:after {
        display: none;
      }
    }
  }

  .sidebar & {
    &-title {
      margin-bottom: 0.5em;
      @include font-size($font-size__h5);
    }

    .container {
      padding: 0;
    }
  }
}

.header-widgets {
  .main-menu + & {
    margin-top: 1em;
  }
}

@import 'about';
@import 'breadcrumbs';
@import 'recent-posts';
@import 'search';
@import 'social-menu';
@import 'taxonomy-cloud';
