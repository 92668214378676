.widget-breadcrumbs {
  ol {
    list-style: none;
    margin: 0;
  }

  li {
    display: inline-block;

    &:after {
      display: inline-block;
      padding: 0 0.25em;
      speak: none;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    span {
      font-weight: bold;
    }
  }
}
