$font-family__main: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Oxygen-Sans, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
$font-family__code: Menlo, Consolas, Monaco, 'Ubuntu Mono', 'Liberation Mono',
  'Lucida Console', monospace;

$line-height__body: 1.5;
$line-height__heading: 1.2;

$font-size__base: 16px;

$font-size__h1: 2;
$font-size__h2: 1.75;
$font-size__h3: 1.5;
$font-size__h4: 1.375;
$font-size__h5: 1.25;
$font-size__h6: 1.125;

$font-size__main: 1;
$font-size__code: 0.9375;
$font-size__blockquote: 1.125;
