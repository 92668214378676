.menu {
  ul {
    list-style: none;
    margin: 0;
  }

  li {
    text-transform: uppercase;

    &.current {
      & > a {
        font-weight: 700;
        pointer-events: none;
      }
    }
  }
}

@import 'main';
@import 'sidebar';

.menu a,
.sub-menu-toggler,
.sidebar-toggler span,
.widget-social_menu a {
  color: $color__bluegrey-700;

  &:focus,
  &:hover {
    color: $color__bluegrey-900;
  }
}
