.entry-meta {
  margin-top: 0.75em;

  span {
    @include font-size(0.9375);
    margin-right: 0.75em;
    font-style: italic;
  }

  .icon {
    margin-right: 0.25em;
  }
}
