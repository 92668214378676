a {
  color: $color__link;
  text-decoration: none;
  transition: color 0.1s ease-in-out;
  background-color: $color__sub-accent;
  padding: 0.3em 0.5em;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;

  &:focus {
    outline-color: $color__accent;
    outline-color: var(--color-accent);
  }

  &:active,
  &:hover {
    outline: 0;
  }

  &:active,
  &:focus,
  &:hover {
    color: $color__link-hover;
  }

  &.button {
    display: inline-block;
  }

  &.horz-space {
    margin-right: 1em;
  }
}

#footer a, .byline a {
  background-color: #fff;
  padding: 0;
}
