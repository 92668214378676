p {
  margin: 0 0 1.5em;

  &:last-child {
    margin: 0;
  }
}

cite,
dfn,
em,
i {
  font-style: italic;
}

blockquote {
  @include font-size($font-size__blockquote);
  font-style: italic;

  margin: 0 0 1.5em;
  padding-left: 1em;
  border-left: 0.2em solid $color__blockquote-border;
}

address {
  margin: 0 0 1.5em;
}

@import 'codes';

abbr,
acronym {
  border-bottom: 1px dotted $color__black;
  cursor: help;
}

ins,
mark {
  background: $color__black;
  text-decoration: none;
}

big {
  font-size: 125%;
}
