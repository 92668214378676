.widget-social_menu {
  ul {
    list-style: none;
    margin: 0;
  }

  li {
    display: inline-block;
    margin-right: 0.625em;

    &:last-child {
      margin-right: 0;
    }

    a {
      @include font-size(1.125);
    }
  }

  .sidebar & {
    li {
      margin-right: 0;
      margin-left: 0.625em;
    }
  }
}
