pre,
code,
kbd,
var,
samp {
  font-family: $font-family__code;

  background: $color__background-code;
  color: $color__text-code;

  padding: 0.1em 0.25em;
}

pre {
  overflow: auto;
  word-wrap: normal;
  text-align: initial;

  margin-bottom: 1.5em;
  padding: 0.75em 1em;

  code,
  kbd,
  var,
  samp {
    background: none;
    color: inherit;

    border: 0;
    padding: 0;
  }
}

code,
kbd,
var,
samp {
  @include font-size($font-size__code);
}

.highlight {
  pre {
    border: 0;
    margin: 0;
  }

  .entry-content & {
    margin-bottom: 1.5em;
  }
}
