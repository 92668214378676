.widget-recent_posts {
  .sidebar & {
    .meta {
      display: none;
    }

    .item {
      margin-top: 0;
    }
  }
}
