.error404 {
  .gopher {
    margin: 0;

    img {
      width: 17.5em;
    }

    a {
      display: block;
      border: 0;
    }

    figcaption {
      margin-top: 1em;
    }

    .title {
      margin-bottom: 0;
    }
  }
}
