ol,
ul {
  padding: 0;
  margin: 0 0 1.5em 1.5em;
}

ul {
  &.task-list {
    list-style: none;

    label {
      font-weight: inherit;
    }

    input {
      width: auto;
      margin-left: -1.5em;
    }
  }
}

li ol,
li ul {
  margin-bottom: 0;
  margin-left: 1.5em;
}

dt {
  font-weight: 700;
}

dd {
  margin: 0 1.5em 1.5em;
}
