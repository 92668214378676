.main-menu {
  & > div {
    display: flex;
    align-items: center;
  }

  li {
    display: inline-block;
    margin-right: 0.625em;

    &:last-child {
      margin-right: 0;
    }
  }
}
