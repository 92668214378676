html {
  box-sizing: border-box;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

blockquote,
q {
  quotes: '' '';

  &:after,
  &:before {
    content: '';
  }

  :last-child {
    margin-bottom: 0;
  }
}

hr {
  border: 0;
  height: 1px;
  margin: 2em 0;
  background-color: $color__background-hr;
}

img {
  max-width: 100%;
  height: auto;
}

figure {
  margin: 1em 0;
}

@import 'links';

@import 'lists';

@import 'tables';
