.home-widgets {
  .sep-before {
    &:before {
      display: none;

      .entry + & {
        display: block;
      }
    }
  }
}
