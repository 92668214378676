.layout-archive {
  .list {
    .item {
      padding-left: 1em;
      .meta {
        flex: 0 0 3.5em;
      }
    }
  }
}

.archive-group {
  &.sep-after {
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}
