.entry-content {
  & > :last-child {
    &,
    & > :last-child {
      margin-bottom: 0;
    }
  }

  a {
    &.footnote-return {
      border-bottom: none;
    }
  }
}
